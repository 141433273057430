<template>
  <div style="width: 100%; float: right" class="fill-height">
    <v-container>
      <v-row
        v-if="listView"
        style="margin-top: 20px"
        class="d-flex justify-center"
      >
        <v-row class="pt-0 mt-0 justify-center">
          <v-col cols="4" class="pt-0 mt-0">
            <v-select
              v-if="location !== {}"
              outlined
              label="Prodajno mjesto"
              :items="locations"
              item-text="name"
              item-value="id"
              v-model="location"
              @change="resetInfinite()"
              dense
            >
            </v-select>
          </v-col>
        </v-row>

        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-card v-for="(item, i) in items" :key="i" hover>
            <v-row
              :class="[
                'ma-0',
                item.status === 'OK'
                  ? 'status-ok'
                  : `${
                      item.status === 'PROCESSING'
                        ? 'status-processing'
                        : 'status-failed'
                    }`,
              ]"
            >
              <v-col
                cols="4"
                sm="3"
                md="1"
                v-if="density !== 'greater'"
                :class="[densityPadding]"
              >
                <v-img
                  v-if="item.picture"
                  :src="item.picture"
                  contain
                  :height="density === 'normal' ? '48' : '68'"
                ></v-img>
                <v-img
                  v-else
                  src="@/assets/no-item.jpg"
                  :height="density === 'normal' ? '48' : '68'"
                ></v-img>
              </v-col>
              <v-col
                cols="8"
                sm="5"
                md="3"
                style="min-width: 100px; max-width: 100%"
                :class="[densityPadding, 'flex-grow-1']"
              >
                <div class="caption grey--text">Naziv artikla</div>
                <div
                  class="text-subtitle-1 one-liner-text"
                  @mouseover="hoverOver(`hover-${item.id}`, item)"
                  @mouseleave="item[`hover-${item.id}`] = false"
                  :id="`hover-${item.id}`"
                  :ref="`hover-${item.id}`"
                >
                  {{ $options.filters.capitalize(item.name) }}
                </div>
                <v-tooltip v-model="item[`hover-${item.id}`]" bottom>
                  <!--Fake activator to avoid an attach property which is not working properly -->
                  <template v-slot:activator="{ on }">
                    <div v-on="on"></div>
                  </template>
                  <div style="max-width: 300px">{{ item.name }}</div>
                </v-tooltip>
              </v-col>
              <v-col cols="4" sm="4" md="2" :class="[densityPadding]">
                <div class="caption grey--text text-right">Cijena artikla</div>
                <div
                  class="text-right"
                  v-for="p in item.prices"
                  :key="p.currency"
                >
                  <div v-if="item.manual_price_input">- {{ p.currency }}</div>
                  <div v-else>
                    {{ p.price | money(100, p.currency) }}
                  </div>
                </div>
              </v-col>
              <v-col
                cols="4"
                sm="4"
                md="2"
                lg="1"
                class="hidden-md-and-down"
                style="min-width: 100px; max-width: 100%"
                :class="[densityPadding, 'flex-grow-1']"
              >
                <div class="caption grey--text text-sm-center text-md-left">
                  Sifra artikla
                </div>
                <div class="text-sm-center text-md-left">{{ item.code }}</div>
              </v-col>
              <v-col
                class="hidden-xs-only"
                sm="4"
                md="2"
                :class="[densityPadding]"
              >
                <div class="caption grey--text text-right">
                  Stanje na skladištu
                </div>
                <div class="text-right">
                  {{ item.type === "GOODS" ? item.stock : "-" }}
                </div>
              </v-col>
              <v-col cols="4" sm="4" md="1" :class="[densityPadding]">
                <div class="caption grey--text">Status artikla</div>
                <div
                  :class="[
                    item.status === 'OK'
                      ? 'success--text'
                      : `${
                          item.status === 'PROCESSING'
                            ? 'info--text'
                            : 'error--text'
                        }`,
                  ]"
                >
                  {{ item.status }}
                </div>
              </v-col>
              <v-col cols="4" sm="1" :class="[densityPadding]">
                <div class="caption grey--text">Prodaje se</div>
                <div
                  :class="[
                    item.active === true
                      ? 'success--text'
                      : `${
                          item.status === 'PROCESSING'
                            ? 'info--text'
                            : 'error--text'
                        }`,
                  ]"
                >
                  {{ item.active === true ? "DA" : "NE" }}
                </div>
              </v-col>
              <v-col cols="2" lg="1" :class="[densityPadding]">
                <div class="caption grey--text text-center">Akcije</div>
                <div class="d-flex align-start justify-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-0 pt-0"
                        :x-small="density === 'greater'"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        @click="openEditForm(item)"
                        @keydown.enter="openEditForm(item)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Uredi artikl</span>
                  </v-tooltip>
                  <!-- <v-tooltip
                    bottom
                    v-if="item.status && item.status.includes('oes not exist')"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-0 pt-0"
                        :x-small="density === 'greater'"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        @click="fixItem(item)"
                        @keydown.enter="fixItem(item)"
                      >
                        <v-icon>mdi-auto-fix</v-icon>
                      </v-btn>
                    </template>
                    <span>Ispravi status</span>
                  </v-tooltip> -->
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        v-else
        class="d-flex justify-center align-center fill-height ma-0 pa-0"
      >
        <v-card
          class="align-center ma-5"
          v-for="(item, i) in items"
          :key="i"
          hover
          height="300"
          width="250"
        >
          <v-img
            height="165"
            width="250"
            v-if="item.picture"
            :src="item.picture"
          >
          </v-img>
          <v-img height="165" width="250" v-else src="@/assets/no-item.jpg">
          </v-img>
          <div class="mr-4 ml-4">
            <div>
              <h3 class="selling-point-title-text one-liner-text">
                {{ $options.filters.capitalize(item.name) }}
              </h3>
              <p class="grey--text mb-1 selling-point-text one-liner-text">
                Status: {{ item.status }}
              </p>
              <p class="grey--text mb-1 selling-point-text one-liner-text">
                Cijena: {{ item.price | money(100) }}
              </p>
            </div>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
      <infinite-loading
        ref="InfiniteLoading"
        @infinite="infiniteHandler"
        spinner="waveDots"
      >
        <div slot="no-more" class="text--secondary font-italic"></div>
        <div slot="no-results" class="text--secondary font-italic"></div>
      </infinite-loading>
    </v-container>
    <add-menu-item
      @refresh="refresh"
      ref="addMenuItem"
      @success="refreshPriceList"
    ></add-menu-item>
    <edit-menu-item
      @refresh="refresh"
      ref="editMenuItem"
      @success="refreshPriceList"
    ></edit-menu-item>
  </div>
</template>
<script>
import { auth, df } from '@/plugins/firebase'
import state from '@/state'
import addMenuItem from '@/modules/point-of-sale/components/menu-item/AddMenuItem'
import editMenuItem from '@/modules/point-of-sale/components/menu-item/EditMenuItem'

import InfiniteLoading from 'vue-infinite-loading'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import barcodeChecker from '@/mixins/barcodeChecker'
import { clone } from '@/plugins/utils'

export default {
  name: 'Pricelist',
  inject: ['showLoader', 'hideLoader', 'showMsgBox'],
  mixins: [flow, applicationSettings, barcodeChecker],
  components: { addMenuItem, InfiniteLoading, editMenuItem },
  data: () => ({
    dialog: false,
    loading: false,
    totalArticles: 0,
    lastVisible: '',
    multiplier: 0,
    lastiItem: false,
    items: [],
    sortedItems: [],
    warehouseModule: false,
    location: undefined,
    locations: [],
    listeners: []
  }),
  computed: {
    listView () {
      return state.isListView()
    },
    search () {
      return state.search
    }
  },
  mounted () {
    this.getAllCurrentCompanyPointsOfSale()
    if (state.getCurrentCompany().warehouse === 'module') {
      this.warehouseModule = true
    }

    this.location = state.getPointOfSale().id
    this.resetInfinite()
    this.getMultiplier()
  },
  watch: {
    listView (nv, ov) {
      if (nv !== ov) {
        if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    },
    search () {
      this.lastVisible = ''
      this.items = []
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    }
  },
  methods: {
    refresh () {
      this.items = []
      this.lastVisible = ''
      this.detachListeners()
      this.resetInfinite()
    },
    resetInfinite () {
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },
    getAllCurrentCompanyPointsOfSale () {
      const docRef = df.doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`)
      docRef.onSnapshot(doc => {
        var locations = Object.values(doc.data().locations)
        locations.forEach((location, index) => {
          this.locations.push(location)
        })
      })
      this.locations.push({ name: 'Sva', location_id: 'all' })
    },
    hoverOver (ref, item) {
      const container = this.$refs[ref]
      if (!container || !container[0]) return
      if (container[0].clientHeight < container[0].scrollHeight) {
        item[ref] = true
      }
    },
    openDialog () {
      this.dialog = true
    },
    openAddMenuItem () {
      this.$refs.addMenuItem.open()
    },
    openEditForm (item) {
      this.$refs.editMenuItem.open(item)
    },
    refreshPriceList (item) {
      this.$forceUpdate()
    },
    modifyItems (change) {
      if (change.type === 'added') {
        const d = change.doc.data()
        d.stock = d.stock / this.multiplier
        d.stock = d.stock.toString().replace('.', ',')
        if (d.prices) {
          Object.keys(d.prices).forEach(key => {
            d.prices[key].currency = key
          })
        }
        this.items.push({ ...d, ...{ [`hover-${change.doc.data().id}`]: false } })
      }

      if (change.type === 'modified') {
        this.items = this.items.map(item => {
          let ret = item
          if (item.id === change.doc.data().id) {
            ret = change.doc.data()
            ret.stock = ret.stock / this.multiplier
            ret.stock = ret.stock.toString().replace('.', ',')
          }
          if (ret.prices) {
            Object.keys(ret.prices).forEach(key => {
              ret.prices[key].currency = key
            })
          }
          return { ...ret, ...{ [`hover-${change.doc.data().id}`]: false } }
        })
      }

      this.sortedItems = clone(this.items.sort((a, b) => a.name.localeCompare(b.name)))
    },
    infiniteHandler ($state) {
      let search = 'name'
      if (this.isBarcodeSearch(this.search)) {
        search = 'barcode'
      }

      const where = this.search ? this.search.toLowerCase() : ''

      let query = df.collection(`location_menu_pricelist/${this.location}/items`)
        .orderBy(search, 'asc')
        .limit(10)
        .startAfter(this.lastVisible)

      if (where) {
        query = query.where(search, '>=', where).where(search, '<=', where + '\uf8ff')
      }

      const listener = query
        .onSnapshot((doc) => {
          doc.docChanges().forEach((change) => {
            this.modifyItems(change)
          })
          if (doc && !doc.empty) {
            if (doc.docs[doc.docs.length - 1]) {
              this.lastVisible = doc.docs[doc.docs.length - 1]
            }
            $state.loaded()
          } else {
            $state.complete()
          }
        }
        // (error) => {
        // }
        )

      this.listeners.push(listener)
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    }
  }
}
</script>
