var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height",staticStyle:{"width":"100%","float":"right"}},[_c('v-container',[(_vm.listView)?_c('v-row',{staticClass:"d-flex justify-center",staticStyle:{"margin-top":"20px"}},[_c('v-row',{staticClass:"pt-0 mt-0 justify-center"},[_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"4"}},[(_vm.location !== {})?_c('v-select',{attrs:{"outlined":"","label":"Prodajno mjesto","items":_vm.locations,"item-text":"name","item-value":"id","dense":""},on:{"change":function($event){return _vm.resetInfinite()}},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}}):_vm._e()],1)],1),_c('v-col',{class:['px-0'],attrs:{"cols":"12","sm":"11","lg":"11"}},_vm._l((_vm.items),function(item,i){return _c('v-card',{key:i,attrs:{"hover":""}},[_c('v-row',{class:[
              'ma-0',
              item.status === 'OK'
                ? 'status-ok'
                : ("" + (item.status === 'PROCESSING'
                      ? 'status-processing'
                      : 'status-failed')) ]},[(_vm.density !== 'greater')?_c('v-col',{class:[_vm.densityPadding],attrs:{"cols":"4","sm":"3","md":"1"}},[(item.picture)?_c('v-img',{attrs:{"src":item.picture,"contain":"","height":_vm.density === 'normal' ? '48' : '68'}}):_c('v-img',{attrs:{"src":require("@/assets/no-item.jpg"),"height":_vm.density === 'normal' ? '48' : '68'}})],1):_vm._e(),_c('v-col',{class:[_vm.densityPadding, 'flex-grow-1'],staticStyle:{"min-width":"100px","max-width":"100%"},attrs:{"cols":"8","sm":"5","md":"3"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Naziv artikla")]),_c('div',{ref:("hover-" + (item.id)),refInFor:true,staticClass:"text-subtitle-1 one-liner-text",attrs:{"id":("hover-" + (item.id))},on:{"mouseover":function($event){return _vm.hoverOver(("hover-" + (item.id)), item)},"mouseleave":function($event){item[("hover-" + (item.id))] = false}}},[_vm._v(" "+_vm._s(_vm.$options.filters.capitalize(item.name))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({},on))]}}],null,true),model:{value:(item[("hover-" + (item.id))]),callback:function ($$v) {_vm.$set(item, ("hover-" + (item.id)), $$v)},expression:"item[`hover-${item.id}`]"}},[_c('div',{staticStyle:{"max-width":"300px"}},[_vm._v(_vm._s(item.name))])])],1),_c('v-col',{class:[_vm.densityPadding],attrs:{"cols":"4","sm":"4","md":"2"}},[_c('div',{staticClass:"caption grey--text text-right"},[_vm._v("Cijena artikla")]),_vm._l((item.prices),function(p){return _c('div',{key:p.currency,staticClass:"text-right"},[(item.manual_price_input)?_c('div',[_vm._v("- "+_vm._s(p.currency))]):_c('div',[_vm._v(" "+_vm._s(_vm._f("money")(p.price,100, p.currency))+" ")])])})],2),_c('v-col',{staticClass:"hidden-md-and-down",class:[_vm.densityPadding, 'flex-grow-1'],staticStyle:{"min-width":"100px","max-width":"100%"},attrs:{"cols":"4","sm":"4","md":"2","lg":"1"}},[_c('div',{staticClass:"caption grey--text text-sm-center text-md-left"},[_vm._v(" Sifra artikla ")]),_c('div',{staticClass:"text-sm-center text-md-left"},[_vm._v(_vm._s(item.code))])]),_c('v-col',{staticClass:"hidden-xs-only",class:[_vm.densityPadding],attrs:{"sm":"4","md":"2"}},[_c('div',{staticClass:"caption grey--text text-right"},[_vm._v(" Stanje na skladištu ")]),_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(item.type === "GOODS" ? item.stock : "-")+" ")])]),_c('v-col',{class:[_vm.densityPadding],attrs:{"cols":"4","sm":"4","md":"1"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Status artikla")]),_c('div',{class:[
                  item.status === 'OK'
                    ? 'success--text'
                    : ("" + (item.status === 'PROCESSING'
                          ? 'info--text'
                          : 'error--text')) ]},[_vm._v(" "+_vm._s(item.status)+" ")])]),_c('v-col',{class:[_vm.densityPadding],attrs:{"cols":"4","sm":"1"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Prodaje se")]),_c('div',{class:[
                  item.active === true
                    ? 'success--text'
                    : ("" + (item.status === 'PROCESSING'
                          ? 'info--text'
                          : 'error--text')) ]},[_vm._v(" "+_vm._s(item.active === true ? "DA" : "NE")+" ")])]),_c('v-col',{class:[_vm.densityPadding],attrs:{"cols":"2","lg":"1"}},[_c('div',{staticClass:"caption grey--text text-center"},[_vm._v("Akcije")]),_c('div',{staticClass:"d-flex align-start justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-0 pt-0",attrs:{"x-small":_vm.density === 'greater',"icon":""},on:{"click":function($event){return _vm.openEditForm(item)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.openEditForm(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Uredi artikl")])])],1)])],1),_c('v-divider')],1)}),1)],1):_c('v-row',{staticClass:"d-flex justify-center align-center fill-height ma-0 pa-0"},_vm._l((_vm.items),function(item,i){return _c('v-card',{key:i,staticClass:"align-center ma-5",attrs:{"hover":"","height":"300","width":"250"}},[(item.picture)?_c('v-img',{attrs:{"height":"165","width":"250","src":item.picture}}):_c('v-img',{attrs:{"height":"165","width":"250","src":require("@/assets/no-item.jpg")}}),_c('div',{staticClass:"mr-4 ml-4"},[_c('div',[_c('h3',{staticClass:"selling-point-title-text one-liner-text"},[_vm._v(" "+_vm._s(_vm.$options.filters.capitalize(item.name))+" ")]),_c('p',{staticClass:"grey--text mb-1 selling-point-text one-liner-text"},[_vm._v(" Status: "+_vm._s(item.status)+" ")]),_c('p',{staticClass:"grey--text mb-1 selling-point-text one-liner-text"},[_vm._v(" Cijena: "+_vm._s(_vm._f("money")(item.price,100))+" ")])])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1)}),1),_c('infinite-loading',{ref:"InfiniteLoading",attrs:{"spinner":"waveDots"},on:{"infinite":_vm.infiniteHandler}},[_c('div',{staticClass:"text--secondary font-italic",attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{staticClass:"text--secondary font-italic",attrs:{"slot":"no-results"},slot:"no-results"})])],1),_c('add-menu-item',{ref:"addMenuItem",on:{"refresh":_vm.refresh,"success":_vm.refreshPriceList}}),_c('edit-menu-item',{ref:"editMenuItem",on:{"refresh":_vm.refresh,"success":_vm.refreshPriceList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }